import { useEffect, useRef, useState, useContext } from 'react';
import { AppGlobalContext, countries } from 'contexts/GlobalContext';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
	Avatar,
	Box,
	ClickAwayListener,
	Grid,
	List,
	ListItemButton,
	ListItemText,
	Paper,
	Popper,
	Typography,
	useMediaQuery,
	Tooltip,
	styled,
} from '@mui/material';

// project imports
import Transitions from 'ui-component/extended/Transitions';

// assets
// import TranslateTwoToneIcon from '@mui/icons-material/TranslateTwoTone';
import useConfig from 'hooks/useConfig';
import argentinaFlag from '../../../../assets/images/widget/argentina.jpg';
import brasilFlag from '../../../../assets/images/widget/brazil.jpg';
import colombiaFlag from '../../../../assets/images/widget/colombia.jpg';
import mexicoFlag from '../../../../assets/images/widget/mexico.jpg';
import useAuth from 'hooks/useAuth';
import { useGlobalQuote } from 'contexts/GlobalQuoteContext';
import { usePagination } from 'hooks/usePagination';

function getCountryFlagImage(object) {
	const flagMap = {
		7: argentinaFlag,
		4: colombiaFlag,
		5: mexicoFlag,
		1: brasilFlag,
	};
	return flagMap[object.id];
}

const StyledImage = styled('img')({});

// ==============================|| LOCALIZATION ||============================== //

const CountrySection = () => {
	const { mainCountry, setCountry } = useContext(AppGlobalContext);
	const [selectedCountry, setSelectedCountry] = useState(mainCountry);
	const { user } = useAuth();
	const { loading } = useGlobalQuote();

	const countryPermissions = user?.permissions?.filter((p) =>
		p.includes('Country')
	);

	const allowedCountries = countries.filter((c) =>
		countryPermissions?.some((p) => p.split(':')[1] === c.pais.toLowerCase())
	);

	const { borderRadius } = useConfig();

	const theme = useTheme();
	const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
	const { paginationModel, handlePaginationChange } = usePagination();

	const [open, setOpen] = useState(false);
	const anchorRef = useRef(null);
	// const [language, setLanguage] = useState(locale);

	const handleListItemClick = (event, country) => {
		setCountry(country);
		// onChangeLocale(lng);
		handlePaginationChange({ ...paginationModel, page: 0 });

		setOpen(false);
	};

	const handleToggle = () => {
		if (loading) return;
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		if (anchorRef.current?.contains(event.target)) {
			return;
		}
		setOpen(false);
	};

	const prevOpen = useRef(open);
	useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current.focus();
		}
		prevOpen.current = open;
	}, [open]);

	useEffect(() => {
		setSelectedCountry(mainCountry);
	}, [mainCountry]);

	return (
		<>
			<Box
				sx={{
					ml: 2,
					[theme.breakpoints.down('md')]: {
						ml: 1,
					},
				}}
			>
				<Avatar
					variant="rounded"
					sx={{
						...theme.typography.commonAvatar,
						...theme.typography.mediumAvatar,
						border: '1px solid',
						borderColor:
							theme.palette.mode === 'dark'
								? theme.palette.dark.main
								: theme.palette.primary.light,
						background:
							theme.palette.mode === 'dark'
								? theme.palette.dark.main
								: theme.palette.primary.light,
						color: theme.palette.primary.dark,
						transition: 'all .2s ease-in-out',
						'&[aria-controls="menu-list-grow"],&:hover': {
							borderColor: theme.palette.primary.main,
							background: theme.palette.primary.main,
							color: theme.palette.primary.light,
						},
						height: {
							xs: '25px',
							md: '35px',
						},
						width: {
							xs: '25px',
							md: '35px',
						},
					}}
					ref={anchorRef}
					aria-controls={open ? 'menu-list-grow' : undefined}
					aria-haspopup="true"
					onClick={handleToggle}
					color="inherit"
				>
					<Tooltip title={mainCountry.pais}>
						<div>
							<StyledImage
								src={getCountryFlagImage(mainCountry)}
								sx={{
									height: '35px',
									marginTop: '4px',
								}}
							/>
						</div>
					</Tooltip>
				</Avatar>
			</Box>

			{allowedCountries.length > 1 && (
				<Popper
					placement={matchesXs ? 'bottom-start' : 'bottom'}
					open={open}
					anchorEl={anchorRef.current}
					role={undefined}
					transition
					disablePortal
					modifiers={[
						{
							name: 'offset',
							options: {
								offset: [matchesXs ? 0 : 0, 20],
							},
						},
					]}
				>
					{({ TransitionProps }) => (
						<ClickAwayListener onClickAway={handleClose}>
							<Transitions
								position={matchesXs ? 'top-left' : 'top'}
								in={open}
								{...TransitionProps}
							>
								<Paper elevation={16}>
									{open && (
										<List
											component="nav"
											sx={{
												width: '100%',
												minWidth: 200,
												maxWidth: 280,
												bgcolor: theme.palette.background.paper,
												borderRadius: `${borderRadius}px`,
												[theme.breakpoints.down('md')]: {
													maxWidth: 250,
												},
											}}
										>
											{allowedCountries.map((country) => (
												<ListItemButton
													key={country.id}
													selected={selectedCountry.id === country.id}
													onClick={(event) =>
														handleListItemClick(event, country)
													}
												>
													<ListItemText
														primary={
															<Grid container>
																<Typography
																	variant="caption"
																	color="textSecondary"
																	sx={{ ml: '8px' }}
																>
																	<img
																		src={getCountryFlagImage(country)}
																		style={{ height: '17px', marginTop: '5px' }}
																		alt={country.pais.toLowerCase()}
																	/>
																</Typography>
																<Typography
																	color="textPrimary"
																	style={{
																		paddingLeft: '10px',
																		paddingTop: '5px',
																		textTransform: 'capitalize',
																	}}
																>
																	{country.pais.toLowerCase()}
																</Typography>
															</Grid>
														}
													/>
												</ListItemButton>
											))}
											{/* <ListItemButton
											selected={selectedCountry.id === 7}
											onClick={(event) =>
												handleListItemClick(event, { id: 7, pais: 'ARGENTINA' })
											}
										>
											<ListItemText
												primary={
													<Grid container>
														<Typography
															variant="caption"
															color="textSecondary"
															sx={{ ml: '8px' }}
														>
															<img
																src={argentinaFlag}
																style={{ height: '17px', marginTop: '5px' }}
															/>
														</Typography>
														<Typography
															color="textPrimary"
															style={{ paddingLeft: '10px', paddingTop: '5px' }}
														>
															Argentina
														</Typography>
													</Grid>
												}
											/>
										</ListItemButton>
										<ListItemButton
											selected={selectedCountry.id === 1}
											onClick={(event) =>
												handleListItemClick(event, { id: 1, pais: 'BRASIL' })
											}
										>
											<ListItemText
												primary={
													<Grid container>
														<Typography
															variant="caption"
															color="textSecondary"
															sx={{ ml: '8px' }}
														>
															<img
																src={brasilFlag}
																style={{ height: '17px', marginTop: '5px' }}
															/>
														</Typography>
														<Typography
															color="textPrimary"
															style={{ paddingLeft: '10px', paddingTop: '5px' }}
														>
															Brasil
														</Typography>
													</Grid>
												}
											/>
										</ListItemButton>
										<ListItemButton
											selected={selectedCountry.id === 4}
											onClick={(event) =>
												handleListItemClick(event, { id: 4, pais: 'COLOMBIA' })
											}
										>
											<ListItemText
												primary={
													<Grid container>
														<Typography
															variant="caption"
															color="textSecondary"
															sx={{ ml: '8px' }}
														>
															<img
																src={colombiaFlag}
																style={{ height: '17px', marginTop: '5px' }}
															/>
														</Typography>
														<Typography
															color="textPrimary"
															style={{ paddingLeft: '10px', paddingTop: '5px' }}
														>
															Colombia
														</Typography>
													</Grid>
												}
											/>
										</ListItemButton>
										<ListItemButton
											selected={selectedCountry.id === 5}
											onClick={(event) =>
												handleListItemClick(event, { id: 5, pais: 'MEXICO' })
											}
										>
											<ListItemText
												primary={
													<Grid container>
														<Typography
															variant="caption"
															color="textSecondary"
															sx={{ ml: '8px' }}
														>
															<img
																src={mexicoFlag}
																style={{ height: '17px', marginTop: '5px' }}
															/>
														</Typography>
														<Typography
															color="textPrimary"
															style={{ paddingLeft: '10px', paddingTop: '5px' }}
														>
															Mexico
														</Typography>
													</Grid>
												}
											/>
										</ListItemButton> */}

											{/* <ListItemButton selected={language === 'fr'} onClick={(event) => handleListItemClick(event, 'fr')}>
                                            <ListItemText
                                                primary={
                                                    <Grid container>
                                                        <Typography color="textPrimary">français</Typography>
                                                        <Typography variant="caption" color="textSecondary" sx={{ ml: '8px' }}>
                                                            (French)
                                                        </Typography>
                                                    </Grid>
                                                }
                                            />
                                        </ListItemButton> */}

											{/* <ListItemButton selected={language === 'ro'} onClick={(event) => handleListItemClick(event, 'ro')}>
                                            <ListItemText
                                                primary={
                                                    <Grid container>
                                                        <Typography color="textPrimary">Română</Typography>
                                                        <Typography variant="caption" color="textSecondary" sx={{ ml: '8px' }}>
                                                            (Romanian)
                                                        </Typography>
                                                    </Grid>
                                                }
                                            />
                                        </ListItemButton> */}

											{/* <ListItemButton selected={language === 'zh'} onClick={(event) => handleListItemClick(event, 'zh')}>
                                            <ListItemText
                                                primary={
                                                    <Grid container>
                                                        <Typography color="textPrimary">中国人</Typography>
                                                        <Typography variant="caption" color="textSecondary" sx={{ ml: '8px' }}>
                                                            (Chinese)
                                                        </Typography>
                                                    </Grid>
                                                }
                                            />
                                        </ListItemButton> */}
										</List>
									)}
								</Paper>
							</Transitions>
						</ClickAwayListener>
					)}
				</Popper>
			)}
		</>
	);
};

export default CountrySection;
