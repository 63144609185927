// Mapeo de las rutas que necesitan ser verificadas con sus permisos correspondientes
const pathsToPermissions = {
	// MEXICO
	'/simulador/PurcheOrder': '_Sourcing',
	'/simulador/BusinessCase': '_Sourcing',
	'/inbound/inbound': '_Comex',
	'/inboundVS/inboundVS': '_Comex',
	//
	'/simulador/CreateSimu/:status': '_Comex',
	'/simulador/update-simulador/:estnumber/:vers': '_Comex',
	'/simulador/details': '_Comex',
	'/simulador/details/:estnumber/:vers': '_Comex',
	'/inbound/CreateInbound': '_Comex',
	'/inbound/update-inboud/:estnumber/:vers': '_Comex',
	'/inbound/details': '_Comex',
	'/inbound/details/:estnumber/:vers': '_Comex',

	/* SALES TRACKING */
	'sales-tracking': 'SalesTracker:view',
	'sales-tracking-glossary': 'SalesTracker:view',

	/* Settings */
	settings: 'SalesTracker:view',

	// PRICING
	'/pricing/pricing-tool': 'page:pricing',
	// "/pricing/pricing-tool-figma": "page:pricingSales",
	'/pricing/pricing-tool-grid': 'page:pricing',
	// PRODUCTOS VS
	'/productosVS': 'abm:read',
	// TABLAS MAESTRAS -> TARIFAS
	'/TARIFAS/Tarifario': 'tarifas:read',
	// TABLAS MAESTRAS -> CONSTANTES
	'/paisRegion/paisRegion': 'abml1:read',
	'/canal/canal': 'abm:read',
	'/carga/carga': 'abml1:read',
	'/impuestos/impuestos': 'abm:read',
	// TABLAS MAESTRAS -> PROVEEDORES
	'/banco/banco': 'abm:read',
	'/fwdtte/fwdtte': 'abm:read',
	'/terminal/terminal': 'abm:read',
	'/custodia/custodia': 'abm:read',
	'/flete/flete': 'abm:read',
	'/truck/truck': 'abm:read',
	'/despachante/despachante': 'abm:read',
	'/deposito/deposito': 'abm:read',
	'/GestDigitalDoc/GestDigitalDoc': 'abm:read',
	'/poliza/poliza': 'abm:read',
	'/proveedoresOem/proveedoresOem': 'abm:read',
	// TABLAS MAESTRAS -> POSICION ARANCELARIA
	'/NCM/NCM': 'abm:read',
	// SERVICE OPERATION -> TALLER
	'/service-operation/ingresar': 'ServiceOp:viewAdmin',
	'/service-operation/admin': 'ServiceOp:viewAdmin',
	'/service-operation/services_operation': 'ServiceOp:view',
	// ...

	// PICKING AND PACKING
	'/pyp/salesorders': 'PyP:view',
	'/pyp/assignments': 'PyP:view',
	'/pyp/picking': 'PyP:view',
	'/pyp/shipping': 'PyP:view',
	'/pyp/shipped': 'PyP:view',

	//Forecast
	'/forecast': 'Forecast:view',
	'/forecast-admin': 'Forecast:viewAdmin',
};

export function mainFilter(mainRoutes, permissions) {
	if (Array.isArray(mainRoutes.children)) {
		mainRoutes.children = mainRoutes.children.map((child) => {
			const requiredPermission = pathsToPermissions[child.path];
			if (requiredPermission && !permissions.includes(requiredPermission)) {
				return { ...child, path: '/NoAutorizado' };
			}
			return child;
		});
	}

	return mainRoutes;
}
