import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export function usePagination(defaultPageSize = 25, defaultPage = 1) {
	const [searchParams, setSearchParams] = useSearchParams();

	const parseQueryParam = (param, defaultValue) => {
		const value = searchParams.get(param);
		const parsedValue = Number.parseInt(value);
		return Number.isNaN(parsedValue) ? defaultValue : parsedValue;
	};

	const pageSize = parseQueryParam('pageSize', defaultPageSize);
	const page = parseQueryParam('page', defaultPage);

	const paginationModel = useMemo(
		() => ({
			pageSize,
			page: page - 1,
		}),
		[pageSize, page]
	);

	const handlePaginationChange = useCallback(
		({ page, pageSize }) => {
			setSearchParams({ pageSize, page: page + 1 });
		},
		[setSearchParams]
	);

	return {
		paginationModel,
		handlePaginationChange,
	};
}
