// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';
import BookIcon from '@mui/icons-material/Book';
import AssessmentIcon from '@mui/icons-material/Assessment';

const icons = {
	IconDashboard,
	IconDeviceAnalytics,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const SalesTracking = {
	id: 'SalesTracking',
	title: <FormattedMessage id="sales-tracking" />,
	icon: icons.IconDashboard,
	type: 'group',
	children: [
		{
			id: 'salesTracking',
			title: <FormattedMessage id="sales-tracking" />,
			type: 'item',
			url: '/sales-tracking',
			icon: AssessmentIcon,
			breadcrumbs: false,
		},
		{
			id: 'salesTrackingGlossary',
			title: <FormattedMessage id="sales-tracking-glossary" />,
			type: 'item',
			url: '/sales-tracking/glossary',
			icon: BookIcon,
			breadcrumbs: false,
		},
	],
};

export default SalesTracking;
