// third-party
import { FormattedMessage } from 'react-intl';

import ExtensionIcon from '@mui/icons-material/Extension';
import TodayIcon from '@mui/icons-material/Today';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const Forecast = {
	id: 'Forecast',
	title: <FormattedMessage id="Forecast" />,
	icon: ExtensionIcon,
	type: 'group',
	children: [
		{
			id: 'forecast',
			title: <FormattedMessage id="Forecast" />,
			type: 'item',
			url: '/forecast',
			icon: TodayIcon,
			breadcrumbs: false,
		},
		{
			id: 'forecastAdmin',
			title: <FormattedMessage id="Admin" />,
			type: 'item',
			url: '/forecast-admin',
			icon: AdminPanelSettingsIcon,
			breadcrumbs: false,
		},
	],
};

export default Forecast;
